import React, { useEffect, useState, useRef, useContext } from 'react';
import IframeResizer from 'iframe-resizer-react';

import PardotContext from '../../context/PardotContext';

function getUserDevice() {
	const userAgent = navigator.userAgent;
	if (
		/Mobile|Android|iPhone|iPad|iPod|Windows Phone|BlackBerry|Kindle|Silk|Opera Mini|IEMobile|Tablet/i.test(
			userAgent
		)
	) {
		return 'Mobile Device';
	} else if (/Tablet|iPad/i.test(userAgent)) {
		return 'Tablet';
	} else {
		return 'Desktop';
	}
}

const PardotForm = ({ url }) => {
	const [formUrl, setFormUrl] = useState('');
	const iframeRef = useRef(null);

	const {
		utm,
		setUtm,
		email: contextEmail,
		setEmail,
	} = useContext(PardotContext);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const pageUrlWithoutUtm = window.location.href.split('?')[0];
			const userDevice = getUserDevice();
			const encodedUserDevice = encodeURIComponent(userDevice);
			const encodedPageURL = encodeURIComponent(pageUrlWithoutUtm);
			const deviceTypeReferral = `&Device_Type=${encodedUserDevice}&Form_Referral_URL=${encodedPageURL}`;
			const email = `&email=${contextEmail}`;

			if (!utm) {
				// If utm is not set, construct it based on the current page URL
				const utmFromPageURL = window.location.search.substring(1); // remove leading "?"
				setUtm(utmFromPageURL);
			}

			// Combine utm and deviceTypeReferral to form the final iframe src
			const separator = url.includes('?') ? '&' : '?';
			setFormUrl(
				url +
					separator +
					(utm
						? url.includes('?')
							? ''
							: utm + deviceTypeReferral
						: utm && contextEmail
						? url.includes('?')
							? ''
							: utm + deviceTypeReferral + email
						: deviceTypeReferral + email)
			);
		}
	}, [url, utm, setUtm, contextEmail]);

	return (
		<>
			{!!formUrl.length ? (
				<IframeResizer
		      className="pardotform"
					ref={iframeRef}
					src={formUrl}
					allowTransparency={true}
					style={{ width: '1px', minWidth: '100%', border: 0 }}
				/>
			) : null}
		</>
	);
};

export default PardotForm;
