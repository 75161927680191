import React, { useContext, useState, useEffect } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Box,
  Container,
  useMediaQuery,
} from '@material-ui/core';

// import useMarketo from '../../hooks/useMarketo';
import FormModalContext from '../../context/FormModalContext';
import FormContext from '../../context/FormContext';
import './marketo-styles/styles.css';
import { hiddenFields } from '../../utilities/hiddenFields';
import PardotForm from './PardotForm';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  contact: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  header: {
    fontWeight: 600,
    textAlign: 'center',
    fontSize: '2.1rem',
    color: theme.white,
    maxWidth: '100%',
    [theme.breakpoints.down('md')]: {
      lineHeight: 1.2,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
    },
  },
  // headerContactUs: {
  // 	fontWeight: 600,
  // 	textAlign: 'center',
  // 	fontSize: '1.9rem',
  // 	color: theme.white,
  // 	[theme.breakpoints.down('md')]: {
  // 		lineHeight: 1.2,
  //   },
  // 	[theme.breakpoints.down('xs')]: {
  // 		fontSize: '1.5rem',
  // 	},
  // },
  modalHeader: {
    fontWeight: 600,
    textAlign: 'center',
    fontSize: '1.9rem',
    color: theme.white,
    padding: '0 24px',
    // marginBottom: '1.5rem',
    [theme.breakpoints.down('md')]: {
      lineHeight: 1.2,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
    },
  },
  subText: {
    fontSize: '20px',
    textAlign: 'center',
  },
  formContainer: {
    maxWidth: '100%',
    width: '75vw',
    // padding: '16px 30px',
    background: theme.white,
    // border: `1px solid ${theme.lightGray}`,
    borderRadius: '20px',
    boxShadow: '0 10px 40px 0px rgba(0,0,0,0.15)',
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
    },
  },
  form: {
    maxHeight: 'auto',
    overflow: 'hidden',
    padding: '0 24px !important',
    // [theme.breakpoints.down('lg')]: {
    // 	maxHeight: '465px',
    // },
    [theme.breakpoints.down('md')]: {
      // maxHeight: '840px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 10px',
    },
  },
  formImagesItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const ContactForm = ({
  baseUrl = '//app-sj02.marketo.com',
  munchkinId = '343-MGE-042',
  formId,
  callback,
  contactForm = {
    header: 'Find the WorkWave solution for you!',
    body: '',
    // privacyPolicy:
    // 	'*By submitting this form you agree to our terms and conditions and our Privacy Policy which explains how we may collect, use and disclose your personal information including to third parties.',
  },
  modal,
  contactUs,
  open,
  noForm,
  formImages,
  location,
  pardotUrl,
}) => {
  const { header, body, privacyPolicy } = contactForm;

  const classes = useStyles();
  const sm = useMediaQuery('(max-width: 600px)');
  return (
    <Container style={{ display: noForm && 'none' }}>
      <div
        className={classes.contact}
        style={{
          marginTop: modal || contactUs ? 0 : sm ? '2rem' : '0',
          marginBottom: '0',
        }}
      >
        <>
          <Grid
            container
            direction='column'
            justifyContent='center'
            alignItems='center'
            spacing={2}
          >
            {body && (
              <Grid item className={classes.subText}>
                <Typography
                  variant='body1'
                  className={classes.subText}
                  style={{ color: modal ? '#002D5C' : 'white' }}
                >
                  {body}
                </Typography>
              </Grid>
            )}
          </Grid>
        </>
        <Box
          className={classes.formContainer}
          style={{
            // padding: '16px',
            marginTop: !contactUs && '0',
          }}
        >
          <div
            style={{
              background: contactUs ? '#2A7ABC' : '#3FA529',
              borderRadius: '16px 16px 0 0',
              padding: '.5rem 0',
              width: '100%',
              marginBottom: '1.5rem',
            }}
          >
            {!!formImages ? (
              <Grid item className={classes.formImagesItem}>
                {formImages.map((formImage) => (
                  <a href={formImage.link} target='_blank' rel='noreferrer'>
                    <GatsbyImage
                      image={formImage.image?.asset?.gatsbyImageData}
                      alt=''
                      style={{ margin: '0 1.25rem' }}
                    />
                  </a>
                ))}
              </Grid>
            ) : null}
            {header && (
              <Grid container justifyContent='center'>
                <Grid item>
                  <Typography
                    variant='h2'
                    className={modal ? classes.modalHeader : classes.header}
                  >
                    {header}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </div>
          {!!pardotUrl ? (
            <div style={{ padding: '0rem 1rem' }}>
              <PardotForm url={pardotUrl} />
            </div>
          ) : (
            <form id={`mktoForm_${formId}`} className={classes.form} />
          )}
          <Grid
            style={{ paddingBottom: '25px' }}
            container
            justifyContent='center'
            alignItems='center'
            direction='row'
          >
            {/* <Grid item xs={10}>
              <Typography
                variant='body2'
                style={{
                  marginTop: '1rem',
                  color: '#8394A2',
                  textAlign: contactUs ? 'center' : 'left',
                  paddingBottom: sm && '1rem',
                  fontSize: contactUs && '.8rem',
                }}>
                {privacyPolicy}
              </Typography>
            </Grid> */}
          </Grid>
        </Box>
      </div>
    </Container>
  );
};
